@import './_theme';
@import "primeicons/primeicons.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

form input[type="text"] {
    text-transform: uppercase;
}

html {
    font-size: 62.5%;
}

body,
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    padding: 0;
}

.p-password {
    width: 100%;
}

.login-inputs {
    height: 5.6rem;
    font-size: 1.6rem;

    .p-inputtext {
        font-size: 1.6rem;
    }
}

.pi {
    font-size: 2rem !important;
}

.p-toast {
    width: 40rem;
}

small {
    font-size: 1.2rem;
    color: var(--red-500);
    margin-top: 0.6rem;
    text-indent: 10px;
}

.dev-grid {
    display: grid;
    place-items: center;
    background-color: #ff5e00;

    p {
        padding: 1.2rem 0 !important;
        color: white !important;
        font-size: 1.2em !important;
    }
}

.ql-editor ol {
    > li {
        .ql-ui::before {
            content: none !important;
        }
        list-style-type: none;
        counter-increment: list-0;
        &:before {
          content: counter(list-0) '.';
          position: absolute;
          margin-left: -1.5em;
          font-weight: bold;
        }
      }

    @for $i from 1 through 8 {
        counter-reset: item-#{$i};

        li.ql-indent-#{$i} {
            list-style-type: none;

            .ql-ui::before {
                content: none !important;
            }

            &:before {
                content: counter(list-0) '.' counter(list-#{$i}, decimal) '.';
                position: absolute;
                margin-left: -3em;
                font-weight: bold;
            }
        }
    }
}